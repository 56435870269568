import React from "react"
import {
  Section,
  SectionHeading,
  SectionLedeDiv
} from "../../../styledComponents/section"
import ConsultationForm from "../../../components/formConsultation"
import { ContactSection } from "../../../styledComponents/contact"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Contact = ({ data, location }) => {
  return (
    <>
    <Layout displayNavBorder location={location}>
      <SEO title="Distance Healing Form" description="Clients are to fill the form in prior to the consultation with Karina" />
      <Section>
        <Heading level={1}>
         Distance Healing Form
        </Heading>
        <SectionLedeDiv>
          <p>Please fill in the following form ahead of your session with Karina.</p>
        </SectionLedeDiv>
        <ContactSection>
          <ConsultationForm />
        </ContactSection>
      </Section>
    </Layout>
    </>
  )
}

export default Contact
